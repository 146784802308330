import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
// import "./ConceptNew.scss";
import style from "./ConceptRevamped.module.scss";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import lockIcon from "../../assets/lock.png";
// import padlockIcon from "../../assets/padlock.png";
import padlockIcon from "../../assets/lock.png";
import {
    getApiUrl,
    getUserAuthToken,
    getAllConcepts,
    listAllUnskippedResources,
    conceptRenameAPI,
    resourceRenameAPI,
    ExamSpaceUploadResource,
    GetUserInfoAPI,
    getAllExamSpaces
} from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import closeIcon from "../../assets/small-close.png"
import smallSVG from "../../assets/loading-svg-orange.svg"
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
import downArrowIcon from "../../assets/new/down-arrow.svg"
import eyeIcon from "../../assets/new/eye.svg"
import playIcon from "../../assets/new/play.svg"
import playIconWithCircle from "../../assets/new/playIconWithCircle.svg"
import rocketIcon from "../../assets/new/rocket.svg"
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { ReactComponent as SearchIcon} from "../../assets/new/search-black-icon.svg"
import { ReactComponent as FolderIcon} from "../../assets/new/folder.svg"


export default function ConceptRevamped() {
    // page initialize
    const [resource_available, set_resource_available] = useState(true);
    const [topic_available, set_topic_available] = useState(true);
    const [data_available, set_data_available] = useState(true);
    const [question_available, set_question_available] = useState(true);
    // check for resource topic and topic question is genertated
    const isAllResourceTopicGenerated = useRef(false);
    const isAllTopicQuestionGenerated = useRef(false);



    const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
    const global_default_examspace_id = useRef("");
    
    var token = getUserAuthToken();
    var apiUrl = getApiUrl();
    const navigate = useNavigate();
    const [conceptdata, setConceptData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [resourceid, setResourceId] = useState("");
    const [searchvalue, setSearchValue] = useState("");
    const [tempInterval, setTempInterval] = useState(0);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));
    const [question_count, set_question_count] = useState(null);
    const [topic_gif_visible, set_topic_gif_visible] = useState(false);
    const [concept_json_data_backup, set_concept_json_data_backup] = useState(false);
    const [showExamspacePopup, setShowExamspacePopup] = useState("false");
    const [examspaceData, setExamspaceData] = useState([]);
    const [defaultExamspace, setDefaultExamspace] = useState({});
    const [chapters_topics_btn, set_chapters_topics_btn] = useState("chapters");
    
    const mobileContextMenuVisibility = useRef(null);
    const mobileResponsiveTopicId = useRef(null);
    const setIntervalIdList = useRef([]);
    const conceptQuestionGenerationIntervalId = useRef(null);
    const conceptQuestionListIntervalId = useRef(null);
    const selectedResourceId = useRef("");
    const editConceptTitleOriginalInfo = useRef(null);
    const modifiedConceptTitle = useRef(null);
    const editResourceTitleOriginalInfo = useRef(null);
    const modifiedResourceTitle = useRef(null);
    const fileUploadRef = useRef(null);
    
    // mouse click event listener
    useEffect(()=>{
        // setLoaderStatus(true)
        proxyCallGetDefaultExamspaceIdUtility()
        const handleClick = (event) => {
            if(editConceptTitleOriginalInfo.current !== null){
                if(event.target.className !== style["resource-topic-card-topic-title"]){
                    if(modifiedConceptTitle.current === editConceptTitleOriginalInfo.current["concept-title"]){
                        editConceptTitleOriginalInfo.current = null;
                        modifiedConceptTitle.current = null;
                        resetConceptTitle();
                    } else {
                        handleConceptRename();
                    }
                }
            }
            if(editResourceTitleOriginalInfo.current !== null){
                if(event.target.className !== style["resource-card-title"]){
                    if(modifiedResourceTitle.current === editResourceTitleOriginalInfo.current["resource-title"]){
                        editResourceTitleOriginalInfo.current = null;
                        modifiedResourceTitle.current = null;
                        resetResourceTitle();
                    } else {
                        handleResourceRename();
                    }
                }
            }
        };
        document.addEventListener('click', handleClick);
        return()=>{
            document.removeEventListener('click', handleClick);
            setIntervalIdList.current.forEach((intervalId)=>{
                clearInterval(intervalId);
            });        
            clearInterval(conceptQuestionGenerationIntervalId.current);
            clearInterval(conceptQuestionListIntervalId.current);
            localStorage.removeItem("selectedResourceIdArray");
        }
    },[sharedUserInfo]);
    
    async function proxyCallGetDefaultExamspaceIdUtility(){
        let response = await getDefaultExamspaceIdUtility();
        checkEmptyState();
    }

    function closeExamSpacePopup() {
        setShowExamspacePopup("false")
    }

    async function getDefaultExamspaceIdUtility(){
        return new Promise( async(resolve, reject) => {
            let default_examspace_id = "";
            if( Object.keys(sharedUserInfo).length > 0){
                default_examspace_id = sharedUserInfo.default_examspace_id;
                global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
                resolve(default_examspace_id);
            } 
            else {
                let getResponse = await GetUserInfoAPI()
                if (getResponse.status) {
                    if(getResponse.data.length > 0){
                        if(Object.keys(getResponse.data[0]).length > 0){
                            setSharedUserInfo(getResponse.data[0]);
                            default_examspace_id = getResponse.data[0]["default_examspace_id"];
                            global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
                            resolve(default_examspace_id);
                        }
                    }
                }
            }
            
        });
    } 


    async function checkEmptyState(){
        try{
            let examspace_api_response = await getAllExamSpaces();
            if (examspace_api_response.data && examspace_api_response.data.length > 0) {
                let selectedExamspaceData = examspace_api_response.data.find(item => item.id === global_default_examspace_id.current);
                if (selectedExamspaceData["resources"].length > 0) {
                    setExamspaceData(examspace_api_response.data)
                    const defaultExamspaceDict = examspace_api_response.data.filter(obj => obj.is_default === true);
                    console.log('defaultExamspaceDict', defaultExamspaceDict)
                    setDefaultExamspace(defaultExamspaceDict[0])

                    set_resource_available(true);
                    let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
                    if(conceptResponse.status){
                        set_topic_available(true);
                        if(conceptResponse.data.length > 0){
                            set_topic_available(true);
                            setConceptData(conceptResponse.data);
                            setFilterData(conceptResponse.data);
                            setTimeout(()=>{
                                openTopicList(conceptResponse.data[0].resource_id);
                            },100);

                            const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
                            const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
                                resource.topic_list.every(topic => topic.question_total_count > 0)
                            );
                            isAllResourceTopicGenerated.current = isResourceTopicGenerated;
                            isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
                            if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
                                setIntervalIdList.current.forEach((intervalId)=>{
                                    clearInterval(intervalId);
                                });
                            }
                            // if (conceptResponse.data.length) {
                            //     setTimeout(()=>{
                            //         openTopicList(conceptResponse.data[0].resource_id);
                            //     },100);
                            // }

                        } else {
                            set_topic_available(false);
                            if(setIntervalIdList.current.length <= 0){
                                continuousPollFucntion()
                            }
                        }          
                    } else {
                        set_topic_available(false);
                        toast.error(conceptResponse.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });                        
                    }
                } else {
                    set_resource_available(false);
                    // setLoaderStatus(false)
                } 
            } else {
                // setLoaderStatus(false)
                toast.error(examspace_api_response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            
        } catch (error) {
            console.error("concept", error)
        }
    }


    function continuousPollFucntion(){
        let timeDuration  = 0;
        let tempIntervalId = setInterval(async ()=>{
            timeDuration += 20;
            if(timeDuration >= 600){
                setIntervalIdList.current.forEach((intervalId)=>{
                    clearInterval(intervalId);
                });                    
            }
            checkEmptyState();
        }, 20000);
        setIntervalIdList.current.push(tempIntervalId);
    }


    async function handleConceptRename(){
        let body = {
            "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
            "concept_title": modifiedConceptTitle.current
        }
        if(modifiedConceptTitle.current.toString().length >= 3){
            let apiResponse = await conceptRenameAPI(body);
            if(apiResponse){
                editConceptTitleOriginalInfo.current = null;
                modifiedConceptTitle.current = null;
                getConceptsData()
                resetConceptTitle()
            }
        } else {
            toast.error("Concept title must be atleast 3 characters", {
                position: toast.POSITION.TOP_CENTER,
            });
            setConceptData([])
            getConceptsData()
            editConceptTitleOriginalInfo.current = null;
            modifiedConceptTitle.current = null;
            setTimeout(()=>{
                let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                if(selectedResourceId != null && selectedResourceId != undefined){
                    openTopicList(selectedResourceId)
                }
            },1000)
        }
    }


    async function handleResourceRename(){
        let body = {
            "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
            "resource_title": modifiedResourceTitle.current
        }
        if(modifiedResourceTitle.current.toString().length >= 3){
            let apiResponse = await resourceRenameAPI(body);
            if(apiResponse){
                editResourceTitleOriginalInfo.current = null;
                modifiedResourceTitle.current = null;
                getConceptsData()
                resetResourceTitle()
            }
        } else {
            toast.error("File title must be atleast 3 characters", {
                position: toast.POSITION.TOP_CENTER,
            });        
            setConceptData([])
            getConceptsData()
            editResourceTitleOriginalInfo.current = null;
            modifiedResourceTitle.current = null;
            setTimeout(()=>{
                let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                if(selectedResourceId != null && selectedResourceId != undefined){
                    openTopicList(selectedResourceId)
                }
            },1000)        
        }
    }

    
    async function getConceptsData(){
        // const requestOptions = {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: "Bearer " + token,
        //     },
        // };
        // fetch(`${apiUrl}/concepts`, requestOptions)
        // .then((response) => response.json())
        // .then((response) => {
        //     if (response.status) {
        //         setConceptData(response.data);
        //         setFilterData(response.data);
        //         if (response.data.length) {
        //             if(selectedResourceId.current === ""){
        //                 setResourceId(response.data[0].resource_id);
        //             } else {
        //                 setResourceId(selectedResourceId.current);
        //             }
        //         }
        //     }
        // });
        let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
        if(conceptResponse.status){
            set_topic_available(true);
            if(conceptResponse.data.length > 0){
                set_topic_available(true);
                setConceptData(conceptResponse.data);
                setFilterData(conceptResponse.data);
                setTimeout(()=>{
                    openTopicList(conceptResponse.data[0].resource_id);
                },100);

                const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
                const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
                    resource.topic_list.every(topic => topic.question_total_count > 0)
                );
                isAllResourceTopicGenerated.current = isResourceTopicGenerated;
                isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
                if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
                    setIntervalIdList.current.forEach((intervalId)=>{
                        clearInterval(intervalId);
                    });
                }
                // if (conceptResponse.data.length) {
                //     setTimeout(()=>{
                //         openTopicList(conceptResponse.data[0].resource_id);
                //     },100);
                // }

            } else {
                set_topic_available(false);
                if(setIntervalIdList.current.length <= 0){
                    continuousPollFucntion()
                }
            }          
        } else {
            set_topic_available(false);
            toast.error(conceptResponse.message, {
                position: toast.POSITION.TOP_CENTER,
            });                        
        }        
    }



    const handleSearch = (searchTerm) => {
        console.log('searchTerm', searchTerm)
        try {
            if (filterdata.length > 0 && filterdata[0].topic_list) {
                const filtered = filterdata[0].topic_list.filter((question) => {
                    return question.name.toLowerCase().includes(searchTerm.toLowerCase());
                });
                
                setConceptData([
                    {
                        resource_id: filterdata[0].resource_id,
                        resource_title: filterdata[0].resource_title,
                        topic_list: filtered,
                    },
                ]);
            }
            
            if (searchTerm === "") {
                getConceptsData();
            }
        } catch (error) {
            console.error("Error in handleSearch:", error);
        }
    };
    
    function openMobileContextMenu(target){
        
        let menu_element = target.parentNode.parentNode.parentNode.nextSibling;
        
        if(menu_element.style.display === "block"){
            menu_element.style.display = "none";
        } else if(menu_element.style.display === "" || menu_element.style.display === "none"){
            let menu_list = document.getElementsByClassName("concept-responsive-context-menu-container");
            Array.from(menu_list).forEach((element) => {
                element.style.display = "none";
            });
            menu_element.style.display = "block";
        }
    }
    
    function CloseMobileContextMenu(target){
        let menu = target.parentNode.parentNode;
        menu.style.display = "none";
    }
    
    function handleUploadSuccess(response){
        checkEmptyState();
    }
    
    function triggerFileUpload() {
        console.log(global_default_examspace_id.current)
        fileUploadRef.current.triggerFileInput();
    }
    
    function openTopicList(res_id){
        let topic_list_element = document.getElementById(`resource-card-topic-list-${res_id}`);
        let res_triangle_element = document.getElementById(`resource-card-open-handle-${res_id}`);
        try {
            if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
                if(topic_list_element.style.display === "" || topic_list_element.style.display === "none" ){
                    topic_list_element.style.display = "block";
                    res_triangle_element.style.rotate = "0deg";
                } else {
                    topic_list_element.style.display = "none";
                    res_triangle_element.style.rotate = "180deg";
                }
            }
        } catch(e){
            console.error(e)
        }
    }
    
    
    async function handleConceptTitleEdit(event, conceptInfo){
        resetConceptTitle(event);
        if(editConceptTitleOriginalInfo.current !== null){
            if(modifiedConceptTitle.current !== editConceptTitleOriginalInfo.current["concept-title"]){
                let body = {
                    "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
                    "concept_title": modifiedConceptTitle.current
                }
                if(modifiedConceptTitle.current.toString().length >= 3){
                    let apiResponse = await conceptRenameAPI(body);
                    if(apiResponse){
                        // new value set
                        getConceptsData()
                        setConceptTitleInfo(conceptInfo)
                    }
                } else {
                    toast.error("Concept title must be atleast 3 characters", {
                        position: toast.POSITION.TOP_CENTER,
                    });          
                    setConceptData([])
                    getConceptsData()
                    setTimeout(()=>{
                        let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                        if(selectedResourceId != null && selectedResourceId != undefined){
                            openTopicList(selectedResourceId)
                            editConceptTitleOriginalInfo.current = null;
                            modifiedConceptTitle.current = null;
                        }
                    },1000)
                }
            } else {
                setConceptTitleInfo(conceptInfo)
            }
        } else{
            setConceptTitleInfo(conceptInfo)
        }
    }
    
    function setConceptTitleInfo(conceptInfo){
        editConceptTitleOriginalInfo.current = null;
        modifiedConceptTitle.current = null;
        editConceptTitleOriginalInfo.current = {
            "concept-id": conceptInfo.id,
            "concept-title": conceptInfo.name,
        }
        modifiedConceptTitle.current = conceptInfo.name
    }
    function resetConceptTitle(event=undefined){
        let tags = document.getElementsByClassName(style["resource-topic-card-topic-title"]);
        Array.from(tags).forEach((element) => {
            element.style.boxShadow = "";
            element.style.backgroundColor = "#fff";
            element.style.padding = "0px";
        });
        if(event !== undefined){
            event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
            event.target.style.backgroundColor = "#fff";
            event.target.style.padding = "0px 5px";
        }
    }
    async function handleResourceTitleEdit(event, resourceInfo){
        resetResourceTitle(event);
        if(editResourceTitleOriginalInfo.current !== null){
            if(modifiedResourceTitle.current !== editResourceTitleOriginalInfo.current["resource-title"]){
                let body = {
                    "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
                    "resource_title": modifiedResourceTitle.current
                }
                if(modifiedResourceTitle.current.toString().length >= 3){
                    let apiResponse = await resourceRenameAPI(body);
                    if(apiResponse){
                        // new value set
                        getConceptsData()
                        setResourceTitleInfo(resourceInfo)
                    }
                } else {
                    toast.error("File title must be atleast 3 characters", {
                        position: toast.POSITION.TOP_CENTER,
                    });          
                    setConceptData([])
                    getConceptsData()
                    setTimeout(()=>{
                        let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                        if(selectedResourceId != null && selectedResourceId != undefined){
                            openTopicList(selectedResourceId)
                            editResourceTitleOriginalInfo.current = null;
                            modifiedResourceTitle.current = null;
                        }
                    },1000)
                }
            } else {
                setResourceTitleInfo(resourceInfo)
            }
        } else{
            setResourceTitleInfo(resourceInfo)
        }
    }
    
    function setResourceTitleInfo(resourceInfo){
        editResourceTitleOriginalInfo.current = null;
        modifiedResourceTitle.current = null;
        editResourceTitleOriginalInfo.current = {
            "resource-id": resourceInfo.resource_id,
            "resource-title": resourceInfo.resource_title,
        }
        modifiedResourceTitle.current = resourceInfo.resource_title
    }
    function resetResourceTitle(event=undefined){
        let tags = document.getElementsByClassName(style["resource-card-title"]);
        Array.from(tags).forEach((element) => {
            element.style.boxShadow = "";
            element.style.backgroundColor = "#fff";
            element.style.padding = "0px";
        });
        if(event !== undefined){
            event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
            event.target.style.backgroundColor = "#fff";
            event.target.style.padding = "0px 5px";
        }
    }

    const fetchExamSpaceData = async () => {
        let examSpaceResponse = await getAllExamSpaces();
        if (examSpaceResponse.status) {
            if (examSpaceResponse.data.length) {
                setExamspaceData(examSpaceResponse.data)
                const defaultExamspaceDict = examSpaceResponse.data.filter(obj => obj.is_default === true);
                setDefaultExamspace(defaultExamspaceDict[0])
            }
        }
    }

    // ui function
    function showLoadingGif(text){
        return(
            <>
                <div className={style["loading-gif-box"]}>
                    <img src={smallSVG} alt="loading gif" />
                    <p>{text}</p>
                </div>
            </>
        )
    }
    function showProgessBar(answered, unanswered, wrong){
        return(
            <>
                <div className={style["progress-bar"]}>
                    <div 
                        style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
                        className={`${style["progress-bar-child"]}`}>
                    </div>
                    <div 
                        style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
                        className={`${style["progress-bar-child"]}`}>
                    </div>
                    <div 
                        style={{width: `${unanswered}%`, backgroundColor:"var(--light-blue)"}}
                        className={`${style["progress-bar-child"]}`}>
                    </div>
                </div>
            </>
        )
    }
    function showGoUnlimitedBtn(){
        return(
            <>
                <div className={style["go-unlimited-btn"]}>
                    <img src={rocketIcon} alt="rocket icon" />
                    <p>Go unlimited</p>
                </div>
            </>
        )
    }

    const SearchBox = () => (
        <>
            <div className={style["search-container"]}>
                <SearchIcon/>
                <div className={style["search-input-wrapper"]}>
                    <input onChange={(e)=> {handleSearch(e.target.value); setSearchValue(e.target.value)}}  value={searchvalue}  type="text" placeholder='Search'/>
                </div>
            </div>            
        </>
    );

    const ExamspaceSpaceFolder = () => (
        <>
            <div className={`${style['examspace-folder']}`} onClick={(e)=>{setShowExamspacePopup("examSpace")}}>
                <FolderIcon/>
                <p>{Object.keys(defaultExamspace).length > 0 ? defaultExamspace.title : "examspace"}</p>
            </div>
            <ExamSpacePopup data={examspaceData} popup={showExamspacePopup} close={closeExamSpacePopup} reCallGet={fetchExamSpaceData} />      
        </>
    );

    const ChapterTopicToggleBtn = () => (
          <>
            <div className={style["chapters-topics-toggle-btn"]}>
              <article
                onClick={()=>{set_chapters_topics_btn("chapters")}}
                className={chapters_topics_btn === "chapters" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
              >
                Chapters
              </article>
              <article
                onClick={()=>{set_chapters_topics_btn("topics")}}
                className={chapters_topics_btn === "topics" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
              >
                Topics
              </article>
            </div>
          </>
    );

return (
    <>
        <main className={style["concept-container"]}>
            <div className={style["concept-wrapper"]}>
                <div className={style["title-section"]}>
                    <div className={style["title-section-left"]}>
                        <h1 className={`${style["title-section-heading"]} global-h1`}>Questions</h1>
                    </div>
                    <div className={style["title-section-right"]}>
                        <ChapterTopicToggleBtn/>
                        <SearchBox/>
                        <ExamspaceSpaceFolder/>
                        {/* <article className={style["title-section-box-1"]}>
                        </article>
                        <article className={style["title-section-box-2"]}>
                        </article> */}
                    </div>
                </div>
                <section className={style["concept-empty-state-container"]} style={resource_available === false && topic_available === false? { display: "flex" } : { display: "none" }}>
                    <div className={style["quiz-empty-state-content"]}>
                        {/* upload file UI */}
                        <div
                            className={style["quiz-empty-state-upload-file"]}
                        >
                            <p>Upload at least 1 document to see the main concepts to learn !!</p>
                            <FileUploadComponent
                                ref={fileUploadRef}
                                onUploadSuccess={handleUploadSuccess}
                                examspaceId={global_default_examspace_id.current}
                            />
                            <button onClick={triggerFileUpload}>
                                <img src={uploadIcon} alt="upload icon" />
                                Upload a file
                            </button>
                        </div>
                    </div>
                </section>
                <section className={style["concept-empty-state-container"]} style={resource_available === true && topic_available === false ? { display: "flex" } : { display: "none" }}>
                    <div className={style["quiz-empty-state-content"]}>
                        {/* Generate question UI */}
                        <div
                            className={style["quiz-empty-state-generate"]}
                        >
                            <img src={loadingsmall} alt="loading" />
                            <p>We are extracting the main concepts from your document</p>
                        </div>
                    </div>
                </section>

                {
                    resource_available === true && topic_available === true && conceptdata.length > 0
                    ?
                        <>
                        <div className={style["resource-list"]}>
                        {
                            conceptdata.map((resourceItem, resourceIndex) => (
                                <article className={style["resource-card"]} key={resourceIndex}>
                                    <section className={style["resource-card-header"]}>
                                        <div className={style["resource-card-left"]}>
                                            <h2 
                                                className={style["resource-card-title"]}
                                                onClick={(e)=>{handleResourceTitleEdit(e, resourceItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                                                onInput={(e)=>{
                                                    modifiedResourceTitle.current = e.target.innerText;
                                                }}
                                                contentEditable={true}
                                                suppressContentEditableWarning={true}
                                            >
                                                {resourceItem.resource_title}
                                            </h2>
                                        </div>
                                        <div className={style["resource-card-right"]}>
                                            <div className={style["resource-card-right-content"]}>
                                                <div className={style["resource-card-score-box"]}>
                                                    {showProgessBar(20, 35, 45)}
                                                </div>
                                                <div className={style["resource-card-resource-info"]}>
                                                    <p>4 chapters</p>
                                                    <p>{resourceItem.total_question_count} questions</p>
                                                </div>
                                            </div>
                                            <div 
                                                className={style["resource-card-open-handle"]}
                                                id={`resource-card-open-handle-${resourceItem.resource_id}`}
                                                onClick={(e)=>{openTopicList(resourceItem.resource_id)}}>
                                                <img src={downArrowIcon} alt="down arrow"/>
                                            </div>
                                        </div>
                                    </section>
                                    <div className={style["resource-card-topic-list"]} id={`resource-card-topic-list-${resourceItem.resource_id}`}>
                                        {
                                            resourceItem.topic_list.length > 0
                                            ?
                                                <>
                                                    {
                                                        resourceItem.topic_list.map((topicItem, topicIndex) => (
                                                            <div className={style["resource-topic-card"]} key={topicIndex}>
                                                                <div className={style["resource-topic-card-left"]} >
                                                                    <div 
                                                                        className={style["resource-topic-card-topic-title"]}
                                                                        contentEditable={true}
                                                                        suppressContentEditableWarning={true}
                                                                        onClick={(e)=>{handleConceptTitleEdit(e, topicItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                                                                        onInput={(e)=>{
                                                                            modifiedConceptTitle.current = e.target.innerText                                           
                                                                        }}                                                                        
                                                                    > 
                                                                        {topicItem.name}
                                                                    </div>
                                                                    <div className={style["resource-topic-card-topic-pages"]}>
                                                                        pages {topicItem.pages}
                                                                    </div>
                                                                </div>
                                                                <div className={style["resource-topic-card-right"]} >
                                                                    {
                                                                        topicItem.status === "pending"
                                                                        ?
                                                                            topicItem.question_total_count > 0
                                                                            ?
                                                                                <>
                                                                                    <div className={style["resource-topic-card-right-content"]}>
                                                                                        <div className={style["resource-topic-card-topic-scores"]}>
                                                                                            {showProgessBar(20, 35, 45)}
                                                                                        </div>
                                                                                        <div className={style["resource-topic-card-desktop-btns"]}>
                                                                                            <div 
                                                                                                onClick={()=>{navigate( `/conceptquestion?topic_id=${topicItem.id}`)}}
                                                                                                className={style["see-all-questions-desktop-btn"]}>
                                                                                                <img src={eyeIcon} alt="eye icon" />
                                                                                                <p>See {topicItem.question_total_count} questions</p>
                                                                                            </div>                                                                    
                                                                                            <div onClick={(e)=>{navigate(`/quiz?topic-id=${topicItem.id}`)}} className={style["practise-desktop-btn"]}>
                                                                                                <img src={playIcon} alt="play icon" />
                                                                                                <p>Practise</p>
                                                                                            </div>                                                                    
                                                                                        </div>                                                                   
                                                                                        <div className={style["resource-topic-card-mobile-btns"]}>
                                                                                            <div className={style["see-all-questions-mobile-btn"]}>
                                                                                                <img src={eyeIcon} alt="eye icon" />
                                                                                            </div>                                                                    
                                                                                            <div className={style["practise-mobile-btn"]}>
                                                                                                <img src={playIconWithCircle} alt="play icon" />   
                                                                                            </div>                                                                    
                                                                                        </div>                                                                   
                                                                                    </div>
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    {showLoadingGif("Loading your questions..")}  
                                                                                </>
                                                                        :
                                                                            <>
                                                                                {showGoUnlimitedBtn()}
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            :
                                                <>
                                                    <div className={style["resource-topic-generating"]}>
                                                        {showLoadingGif("Loading your topics..")}
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </article>
                                )
                            )
                        }
                        </div>
                        </>
                    :
                        <></>
                }
            </div>
        </main>
        
        {/* <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} /> */}
        <ToastContainer />
        <LoaderSpinner data={loaderStatus}></LoaderSpinner>
    </>
);

}
