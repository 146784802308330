import React from 'react'
import { AiOutlineUndo } from 'react-icons/ai'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import './Topbar.scss'
const Topbar = ({ question, setPopup, markComplete }) => {

    const navigate = useNavigate();

    const goBack = () => {
      navigate(-1)
    };

    return (
        <div className='main_topbar'>

            <div className="back" onClick={goBack}>
                <BsArrowLeft />
            </div>

            <div className="question">
                {question?.statement}
            </div>

            <div className="actions">
                <div className="text" onClick={() => markComplete()}>
                    Mark as complete
                </div>
                <AiOutlineUndo onClick={() => setPopup(false)} className='icon' />
            </div>
        </div>
    )
}

export default Topbar
