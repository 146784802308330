import React, { useEffect, useState, useRef, useContext } from 'react'
import styleExamSpace from './ExamSpacePopup.module.css'
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import plus_orange from '../../assets/plus_orange.png'
import tick_orange from '../../assets/tick_with_round.png'
import { CurrentUserInfo } from '../ContextStore/ContextStore'
import { ToastContainer, toast } from "react-toastify";

import { RiArrowDropDownLine } from "react-icons/ri";
import { postExamSpaceFile, getAllExamSpaces, updateExamSpaceTitle, setDefaultExamSpace, DeleteExamSpace, ImportResourceFile, DeleteResource, GetUserInfoAPI } from '../../helpers/requests';


export default function ExamSpacePopup(props) {
    const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
    // const [enableCreateExamspaceBtn, setEnableCreateExamspaceBtn] = useState(true)
    const enableCreateExamspaceBtn = useRef(true);
    const [examSpaceMenu, setExamSpaceMenu] = useState(false)
    const [fileListId, setFileListId] = useState(null)
    const [showHover, setShowHover] = useState(null)
    const [renameData, setRenameData] = useState({})
    const [showDelSlide, setShowDelSlide] = useState(null)
    const [examSpaceData, setExamSpaceData] = useState([])
    const [selectedFile, setSelectedFile] = useState({})
    const modifiedConceptTitle = useRef(null)

    useEffect(() => {

        setExamSpaceMenu(props.popup)
        setExamSpaceData(props.data)

        if (props.data.length) {
            /*
                when "import existing" button is clicked iterate the examspace data array, if 1st examspace contains 
                files set it's examspace id to "setFileListId()" and break the loop. Else check on the next json.
            */
            for(let i = 0; i < props.data.length; i++){
                if(props.data[i]["resources"].length > 0){
                    setFileListId(props.data[i]["id"]);
                    break;
                }
            }

            setFileListId(props.data[0].id)
            for (const iterator of props.data) {
                if (iterator.is_default) {
                    setSelectedFile(iterator)
                }
            }

            // if data was only one and also that was not a is_default (set a default)
            if (props.data.length === 1) {
                if (!props.data[0].is_default) {
                    handleClickExamSpace(props.data[0])
                }
            }

            if (!fileListId) {
                setFileListId(props.data[0].id)
            }
        }

    }, [props])



    const handleAddExamSpaceFile = async () => {
        if (enableCreateExamspaceBtn.current === true) {
            enableCreateExamspaceBtn.current = false;
            // setEnableCreateExamspaceBtn(false)

            let getFilename = getUniqueFolderName()
            let body = {
                "title": getFilename
            }

            let postFileName = await postExamSpaceFile(body)
            if (postFileName) {
                if (postFileName.status) {
                    toast.success("New examspace created", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: true,
                    });
                    setTimeout(() => {
                        enableCreateExamspaceBtn.current = true;
                    }, 500)
                    props.reCallGet()
                    // let examSpaceResponse = await getAllExamSpaces();
                    // if (examSpaceResponse.status) {
                    //     // setEnableCreateExamspaceBtn(true)
                    //     // setExamSpaceData(examSpaceResponse.data)
                    // }
                } else {
                    toast.error("Examspace creation failed", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: true,
                    });
                }
            }
        }
    }


    // Function to get a unique folder name
    function getUniqueFolderName() {

        let existingFolders = examSpaceData.map(folder => folder.title)

        let baseName = "My Examspace";
        let folderName = baseName;
        let counter = 1;

        while (existingFolders.includes(folderName)) {
            folderName = `${baseName} ${counter}`;
            counter++;
        }

        return folderName;
    }


    const handleClickTitle = (data) => {

        if (Object.keys(renameData).length) {
            if (data.id !== renameData.id) {
                setRenameData(data)
                handleRemoveRenameStyle(renameData)
                handleApplyRenameStyle(data)
            }
        } else {
            setRenameData(data)
            handleApplyRenameStyle(data)
        }

    }

    const handleApplyRenameStyle = (data) => {
        let getAttribute = document.getElementById(`title_${data.id}`)
        getAttribute.style.border = "none"
        getAttribute.style.outline = "none"
        getAttribute.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
        getAttribute.style.backgroundColor = "#fff";
        getAttribute.style.padding = "0px 5px";
    }

    const handleRemoveRenameStyle = (data) => {
        let element = document.getElementById(`title_${data.id}`)
        element.style.boxShadow = "";
        element.style.backgroundColor = "white";
        element.style.padding = "0px"
    }

    const handleRenameApi = async (id) => {

        if (modifiedConceptTitle.current) {
            let body = {
                "id": id,
                "title": modifiedConceptTitle.current
            }

            let updateTitle = await updateExamSpaceTitle(body)
            if (updateTitle) {
                if (updateTitle.status) {
                    props.reCallGet()
                    modifiedConceptTitle.current = null
                }
            }
        }

    }

    //Outside click call API
    useEffect(() => {

        const handleClickOutside = (event) => {

            if (renameData.id) {
                const element = document.getElementById(`title_${renameData.id}`);
                if (element && !element.contains(event.target)) {
                    handleRemoveRenameStyle(renameData)
                    handleRenameApi(renameData.id)
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [renameData]);

    //Outside click call API
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (examSpaceMenu) {
                const element = document.getElementById(`exam_space_box_container`);
                if (element && !element.contains(event.target)) {
                    // setExamSpaceMenu(false)
                    props.close('close')
                    // handleRemoveRenameStyle(renameData)
                    // handleRenameApi(renameData.id)
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [examSpaceMenu]);



    //click exam space folder
    const handleClickExamSpace = async (data) => {
        let body = {
            "default_examspace_id": data.id
        }
        let getResponse = await setDefaultExamSpace(body)
        if (getResponse) {
            if (getResponse.status) {
                props.reCallGet();
                let userInfoResponse = await GetUserInfoAPI();
                if (userInfoResponse.status) {
                    if (userInfoResponse.data.length > 0) {
                        if (Object.keys(userInfoResponse.data[0]).length > 0) {
                            setSharedUserInfo(userInfoResponse.data[0]);
                        }
                    }
                }
            }
        }
    }

    //delete folder API

    const handleDeleteFolder = async (event, data, index) => {


        if (data.is_default) {
            if (examSpaceData.length === 1) {
                toast.error("You need to have at least 1 examspace. Please create a new one before deleting the last.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                // delFunction(data)
                // handleAddExamSpaceFile()
            } else {
                if (examSpaceData.length === index + 1) {
                    handleClickExamSpace(examSpaceData[index - 1])
                    delFunction(data)
                } else {
                    handleClickExamSpace(examSpaceData[index + 1])
                    delFunction(data)
                }
            }
        } else {
            delFunction(data)
        }


        // if (examSpaceData.length === index + 1) {

        // } else {
        //     if (index > 0) {
        //         const prevData = examSpaceData[index - 1];
        //         const nextData = examSpaceData[index + 1];
        //     }
        // }

        event.stopPropagation()

        async function delFunction(data) {
            let getId = data.id
            let gteResponse = await DeleteExamSpace(getId)
            if (gteResponse) {
                if (gteResponse.status) {
                    props.reCallGet()
                    setShowDelSlide(null)
                }
            }
        }

        // 
    }


    const handleSetResource = async (file) => {
        let body = {
            "examspace_id": selectedFile.id,
            "resource_id": file.id,
        }

        let getResponse = await ImportResourceFile(body)
        if (getResponse.status) {
            props.reCallGet()
        }
    }

    const handleRemoveResoure = async (ex_id, re_id) => {

        let getResponse = await DeleteResource(ex_id, re_id)
        if (getResponse) {
            if (getResponse.status) {
                props.reCallGet()
            }

        }
    }


    const handleFindFileThere = (name_id, Res_id) => {

        let fileThere = false

        for (const iterator of selectedFile.resources) {

            if (iterator.id === Res_id) {
                fileThere = true
            }
        }


        return fileThere

    }


    const handleKeyDownTitle = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault()
            handleRemoveRenameStyle(renameData)
            handleRenameApi(renameData.id)
        }
    }

    return (
        <div>
            {/* {children} */}
            {examSpaceMenu === 'examSpace' ?
                <main
                    className={styleExamSpace["exam-space-popup-container"]}
                    style={{ display: "block" }}
                >
                    <div className={styleExamSpace["exam-space-popup-backdrop"]}>
                        <div id='exam_space_box_container' className={styleExamSpace["exam-space-popup-content"]}>
                            <div className={styleExamSpace['x-mark-icon-parent']}>
                                <XMarkIcon onClick={() => props.close('close')} className={styleExamSpace['x-mark-icon']} />
                            </div>
                            <div className={styleExamSpace["exam-space-popup-section"]}>
                                <div className={styleExamSpace["exam-space-heading"]}>
                                    <h2 className={styleExamSpace["exam-space-popup-title"]}>Examspaces</h2>
                                    <img
                                        // style={ enableCreateExamspaceBtn ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }} 
                                        onClick={() => handleAddExamSpaceFile()}
                                        src={plus_orange}
                                        alt='add file'
                                    />
                                </div>
                                <p className={styleExamSpace["exam-space-organize-text"]}>Organize your documents for each exam</p>
                                <div className={styleExamSpace["exam-space-add-mobile"]}>
                                    <img onClick={() => handleAddExamSpaceFile()} src={plus_orange} alt='add file' />
                                    <p>new examspace</p>
                                </div>
                                <div className={styleExamSpace['exam-space-folders-container']}>
                                    {examSpaceData.map((folder, index) => (
                                        <div key={folder.id}
                                            onClick={() => handleClickExamSpace(folder)}
                                            onMouseOver={() => { setShowHover(folder.title); }}
                                            onMouseLeave={() => setShowHover(null)}
                                            className={styleExamSpace['exam-space-folder']}
                                            style={{
                                                border: folder.is_default ? '1px solid black' : '1px solid #D9D9D9'
                                            }}
                                        >
                                            <div>
                                                <p
                                                    id={`title_${folder.id}`}
                                                    onClick={() => handleClickTitle(folder)}
                                                    onInput={(e) => {
                                                        modifiedConceptTitle.current = e.target.innerText
                                                    }}
                                                    onKeyDown={(e) => handleKeyDownTitle(e)}
                                                    contentEditable={true}
                                                    className={styleExamSpace['folder-name']}
                                                    style={{
                                                        color: folder.is_default ? 'black' : '#9399af'
                                                    }}
                                                >
                                                    {folder.title}
                                                </p>
                                                <p className={styleExamSpace['files-count']}>
                                                    {folder.resources.length}&nbsp;
                                                    files
                                                </p>
                                                <p className={styleExamSpace['ques-count']}>
                                                    {folder.examspace_questions_count}&nbsp;
                                                    questions
                                                </p>
                                            </div>
                                            {showHover === folder.title ?
                                                <TrashIcon onClick={(event) => { event.stopPropagation(); setShowDelSlide(folder.title) }} className={styleExamSpace["trash-icon-folder"]} />
                                                :
                                                null
                                            }
                                            {showDelSlide === folder.title ?
                                                <div className={styleExamSpace['exam-space-folder-hover-content']}>
                                                    <p style={{ color: 'white' }}>All files and questions in this examspace will be deleted
                                                    </p>
                                                    <div className={styleExamSpace['exam-folder-btn']}>
                                                        <button onClick={(event) => { event.stopPropagation(); setShowDelSlide(null) }} className={styleExamSpace['exam-folder-keepit']}>Keep it</button>
                                                        <button onClick={(event) => handleDeleteFolder(event, folder, index)} className={styleExamSpace['exam-folder-delete']}>Delete</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                :
                examSpaceMenu === 'import' ?
                    <main
                        className={`${styleExamSpace["exam-space-popup-container"]}`}
                        style={{ display: "block" }}
                    >
                        <div className={styleExamSpace["import-popup-backdrop"]}>
                            <div id='exam_space_box_container' className={styleExamSpace["import-popup-content"]}>
                                <div className={styleExamSpace['x-mark-icon-parent']}>
                                    <XMarkIcon onClick={() => props.close('close')} className={styleExamSpace['x-mark-icon']} />
                                </div>
                                {examSpaceData.map((name) => (
                                    <>
                                        {!name.is_default ?
                                            <div className={styleExamSpace["import-popup-section"]}>
                                                <div
                                                    onClick={() => setFileListId(name.id)} className={styleExamSpace["import-heading"]}>
                                                    {name.resources.length ?
                                                        <RiArrowDropDownLine
                                                            style={{
                                                                rotate: fileListId === name.id ? '0deg' : '270deg'

                                                            }}
                                                            className={styleExamSpace["arrow-down-icon"]} />
                                                        :
                                                        null
                                                    }
                                                    <p
                                                        style={{
                                                            marginLeft: name.resources.length ? '' : '38px'
                                                        }}
                                                        className={styleExamSpace["import-popup-title"]}>
                                                        {name.title}
                                                    </p>
                                                </div>
                                                <p className={styleExamSpace['file-count-text']}>{name.resources.length} files added</p>
                                                {fileListId === name.id ?
                                                    <>
                                                        {name.resources.map((file) => (
                                                            <div
                                                                onMouseOver={() => { setShowHover(file.id); }}
                                                                onMouseLeave={() => setShowHover(null)}
                                                                className={styleExamSpace['import-file-content']}>
                                                                {handleFindFileThere(name.id, file.id) ?
                                                                    <img onClick={() => handleRemoveResoure(selectedFile.id, file.id)} src={tick_orange} className={styleExamSpace['import-file-tick-icon']} />
                                                                    :
                                                                    <img onClick={() => handleSetResource(file)} src={plus_orange} className={styleExamSpace['import-file-icon']} />
                                                                }
                                                                <p className={styleExamSpace['import-file-name']}>
                                                                    {file.resource_display_name.length < 80 ? file.resource_display_name : file.resource_display_name.slice(0, 80) + '...'}
                                                                </p>
                                                                {/* {showHover === file.id ?
                                                                    <TrashIcon onClick={() => handleRemoveResoure(name.id, file.id)} className={styleExamSpace["trash-icon"]} />
                                                                    :
                                                                    null
                                                                } */}
                                                            </div>
                                                        ))
                                                        }
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    </main>
                    : null
            }
            <ToastContainer />
        </div>
    )
}
