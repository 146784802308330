import React, { useEffect, useRef, useState } from "react";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { getConceptQuestionAPI } from "../../helpers/requests";
import styles from "./ConceptQuestion.module.css"
import playIcon from "../../assets/new/play.svg"
import playIconWithCircle from "../../assets/new/playIconWithCircle.svg"
import { useNavigate } from "react-router-dom";
import {ReactComponent as LeftArrowIcon} from "../../assets/new/left-arrow.svg"


export default function ChapterQuestions(){

	const [showLoadingGif, setShowLoadingGif] = useState(false);
	const [apiResponseJson, setApiResponseJson] = useState({});
	const [window_width, set_window_width] = useState(window.innerWidth);
	const navigate = useNavigate();

	useEffect(() => {
		setShowLoadingGif(true);
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("topic_id") !== null && urlParams.get("topic_id") !== undefined) {
			console.log('----------------------')
			console.log(urlParams.get("topic_id"))
			console.log('----------------------')
			getConceptQuestions(urlParams.get("topic_id"))
		}
		const handleResize = () => {
			set_window_width(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	async function getConceptQuestions(topicId){
		let apiResponse = await getConceptQuestionAPI(topicId);
		if(apiResponse && apiResponse.data.question_list.length > 0){
			setApiResponseJson(apiResponse.data);
			setShowLoadingGif(false);
		}
	}

	function showProgessBar(answered, unanswered, wrong){
        return(
            <>
                <div className={styles["progress-bar"]}>
                    <div 
                        style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
                        className={`${styles["progress-bar-child"]}`}>
                    </div>
                    <div 
                        style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
                        className={`${styles["progress-bar-child"]}`}>
                    </div>
                    <div 
                        style={{width: `${unanswered}%`, backgroundColor:"var(--light-blue)"}}
                        className={`${styles["progress-bar-child"]}`}>
                    </div>
                </div>
            </>
        )
    }

	return (
	<>
	{console.log('apiResponseJson', apiResponseJson)}
	<main className={`${styles["container"]}`}>
		<div className={`${styles["content-wrapper"]}`}>
			{/* <div className={`${styles["header"]}`}>
				<div className={`${styles["header-left"]}`}>
					<div className={`${styles["back-btn"]}`}>
						{`< `} Back to questions
					</div>
					<div className={`${styles["topic-tile"]}`}>
						{apiResponseJson && Object.keys(apiResponseJson).length ? apiResponseJson.topic_name : ""}
					</div>
					<div className={`${styles["topic-description-desktop"]}`}>
						<p>pages 1 - 5&nbsp;&nbsp;|&nbsp; &nbsp;</p>
						<p>{apiResponseJson && Object.keys(apiResponseJson).length ? apiResponseJson.topic_name : ""}</p>
					</div>
					<div className={`${styles["topic-description-mobile"]}`}>
						<p>pages 1 - 5</p>
						<p>{apiResponseJson && Object.keys(apiResponseJson).length ? apiResponseJson.topic_name : ""}</p>
					</div>
				</div>
				<div className={`${styles["header-right"]}`}>
				</div>
			</div> */}
			<div onClick={()=>{navigate("/concept")}} className={`${styles["back-btn"]}`}>
				<LeftArrowIcon/>
				<span>back to questions</span>
			</div>
			<div className={`${styles["question-list"]}`}>
				<div className={`${styles["topic-header"]}`}>
					<div className={`${styles["topic-header-left"]}`}>
						<div className={`${styles["topic-header-title"]}`}>
							{apiResponseJson && Object.keys(apiResponseJson).length ? apiResponseJson.topic_name : ""}
						</div>
						<div className={`${styles["topic-header-description"]}`}>
							<p>Pages 1 - 5 </p>
							{window_width > 600 ? <p>|</p> : <></>}
							<p>{apiResponseJson && Object.keys(apiResponseJson).length ? apiResponseJson.topic_name : ""}</p>
							{/* <p>Miraculous Morning Transformation Miraculous Morning Transformation Miraculous Morning Transformation</p> */}
						</div>
					</div>
					<div className={`${styles["topic-header-right"]}`}>
						<div className={`${styles["topic-header-progress-bar"]}`}>
							{showProgessBar(30,40,30)}
						</div>
						<div className={`${styles["topic-header-question-count"]}`}>
							60 questions
						</div>
					</div>
				</div>
				<div className={`${styles["question-list-header"]}`}>
					<div className={`${styles["header-column-1"]}`}>
						Questions
					</div>
					<div className={`${styles["header-column-2"]}`}>
						Status
					</div>
				</div>
				{
					apiResponseJson && Object.keys(apiResponseJson).length > 0 && apiResponseJson.question_list.length > 0
					?
						<>
							{apiResponseJson.question_list.map((questionItem, questionIndex)=>(
								<div 
									className={`${styles["question-card"]}`} 
									key={questionIndex}
									onClick={()=>{navigate(`/quiz?question-id=${questionItem.question_id}`)}}
								>
									<div className={`${styles["question-card-left"]}`}>
										<div className={`${styles["question-title"]}`}>
											{questionItem.question_content.question}
										</div>
									</div>
									<div className={`${styles["question-card-right"]}`}>
										<aside 
										style={true 
											? 
												{ backgroundColor: "var(--light-green)"} 
												: 
												false
													?
													{ backgroundColor: "var(--refresh-violet)"}
													:
													false
													?
														{ backgroundColor: "var(--light-orange)"}
													:
														false
														?
														{ backgroundColor: "var(--light-blue)"}
														:
														""
											}>
										</aside>										
										{/* <div className={`${styles["practise-btn-desktop-btn"]}`}>
											<img src={playIcon} alt="play icon" />
											<p>Practise</p>
										</div>
										<div className={`${styles["practise-btn-mobile-btn"]}`}>
											<img src={playIconWithCircle} alt="play icon" />
										</div> */}
									</div>
								</div>
							))}
						</>
					:
						<></>
				}
			</div>
		</div>
	</main>
	<LoaderSpinner data={showLoadingGif}></LoaderSpinner>
	</>
	);
};
