import "./Quiz.scss";
import { useEffect, useState, useRef, useContext } from "react";
import { CurrentUserInfo, Message_data } from "../../Components/ContextStore/ContextStore";
import { useNavigate } from "react-router-dom";
import {
  getUserAuthToken,
  getApiUrl,
  listAllUnskippedResources,
  getAllConcepts,
  getQuestionUtility,
  ExamSpaceUploadResource,
  GetUserInfoAPI,
} from "../../helpers/requests";
import thumbsDownIcon from "../../assets/thumb-down.png";
import swipeImg from "../../assets/swipe.png";
import FeedbackPopup from "../../Components/FeedbackPopup/FeedbackPopup";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { ToastContainer, toast } from "react-toastify";
import clickHandIcon from "../../assets/click-hand-icon.png";
import { AiOutlineRight, AiOutlineUpCircle } from "react-icons/ai";
import uploadIcon from "../../assets/upload-white-icon.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import closeIcon from "../../assets/new/close-black-icon.svg";
import downArrow from "../../assets/down-arrow-drak-blue.png";
import { ReactComponent as FilterIcon} from "../../assets/new/filter-black-icon.svg";
import questionIcon from "../../assets/question.png";
import searchBlackIcon from "../../assets/new/search-black-icon.svg";
import correctAnsIcon from "../../assets/new/correct-ans-icon.svg";
import orangeLock from "../../assets/orange_lock.png";
import lockIcon from "../../assets/lock.png";
import style from "./Quiz.module.scss";
import downArrowBlack from "../../assets/down-arrow-black.png";
import ContinuousThreeAnswer from "../../Components/ContinuousThreeAnswer/ContinuousThreeAnswer";
import Sidebar from "../../Components/Sidebar/Sidebar";
import blueThumbsDown from "../../assets/blue-thumbs-down.png";
import downArrowIcon from "../../assets/new/down-arrow.svg"
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
//For Zoom
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Viewer } from "@react-pdf-viewer/core";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  MinusCircleIcon,
  MinusIcon,
  PlusCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';

// before quiz ui revamp
export default function Quiz() {
  const { setQuestionData } = useContext(Message_data);
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");

  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();

  const [current_question_content, set_current_question_content] = useState([]);
  const [feedback_popup_options, set_feedback_popup_options] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [option_card_clickable, set_option_card_clickable] = useState(false);
  const [quiz_option_clicked, set_quiz_option_clicked] = useState(false);
  const [show_answer_in_feedback, set_show_answer_in_feedback] =
    useState(false);
  const [allownext, setAllowNext] = useState(false);
  const [nextswipe, setNextSwipe] = useState(false);
  const [data_available, set_data_available] = useState(true);
  const [resource_available, set_resource_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  const [concepts_data, set_concepts_data] = useState([]);
  const [plan_type, set_plan_type] = useState(
    localStorage.getItem("plan_type")
  );
  const [question_filter_array, set_question_filter_array] = useState([]);
  const [first_three_questions_display, set_first_three_questions_display] =
    useState({});
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_question_filter, set_show_question_filter] = useState(true);
  const [show_quiz_filter, set_show_quiz_filter] = useState(false);
  const [show_topic_card_questions, set_show_topic_card_questions] = useState(false);
  const [chapters_topics_btn, set_chapters_topics_btn] = useState("chapters");
  const [enableSuffleMode, setEnableSuffleMode] = useState(false);
  const enableSuffleModeRef = useRef(false);


  //for PDF
  const [getpdf, setpdf] = useState(null);
  const [pagenumber, setPageNumber] = useState(0);

  const [startPosX, setStartPosX] = useState(null);
  const [endPosX, setEndPosX] = useState(null);

  const [pdfopen, setPdfOpen] = useState(false);
  const [pdfloaded, setPdfLoaded] = useState(false);

  const pollIntervalId = useRef(null);
  const containerRef = useRef(null);
  const questions_metadata_list = useRef([]);
  const question_count = useRef(0);
  const progressBarContainer = useRef();
  const progressBar = useRef();
  const quiz_hints_title = useRef(null);
  const quiz_hints_content = useRef(null);
  const quiz_guide_text = useRef(null);
  const quiz_guide_icon = useRef(null);
  const clearSetInterval = useRef(null);
  const answerResponseTimeStart = useRef(null);
  const answerResponseTimeEnd = useRef(null);
  const topicSetInterval = useRef(null);
  const threeMinutesTimer = useRef(0);
  let global_resource_id = useRef(null);
  var transition_next_quiz = useRef(true);
  var global_answer_type = useRef(null);
  const question_filter_content = useRef(null);
  const quiz_status_content = useRef(null);
  const question_counter_helptext = useRef(null);
  const question_x_id = useRef(null);
  const lastThreeQuestionIdArray = useRef([]);
  const conceptArrayDuplicate = useRef([]);
  const feedback_see_ans_btn = useRef(null);
  const pdf_section_container = useRef();

  let initialX = null;
  let delay_new = 4000;

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  // PDF Navigation
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    GoToNextPage,
    GoToFirstPage,
  } = pageNavigationPluginInstance;
  const fileUploadRef = useRef(null);

  useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility();

    const getClickEvent = (event) => {
      try {
        if(event.target.className.includes("question-filter-menu")){
          set_show_quiz_filter(false)
        } else {
          if (event.target.closest(`.${style["question-filter-container"]}`)) {
            set_show_quiz_filter(true)
          } else {
            set_show_quiz_filter(false)
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    window.addEventListener("click", getClickEvent);

    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(pollIntervalId.current);
      localStorage.removeItem("current_question_content");
      window.removeEventListener("click", getClickEvent);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function proxyCallGetDefaultExamspaceIdUtility(){
    let response = await getDefaultExamspaceIdUtility();
    try {
      getQuestionOnce();
    } catch (e) {
      console.error("error", e);
    }
  }


// ========================= IndexedDB Utility Functions ========================

function indexeddbAddData(inputData){
  try{
    let db;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.add({id: 100, data: inputData});
      response1.onsuccess = function(event) {
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error add data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        // console.error("Error adding data: " + event.target.errorCode);
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      // console.error("indexedDB Error" + event.target.errorCode);
    };
  } catch (error) {
    console.error(error)
  }
}


function indexeddbGetData() {
  return new Promise( (resolve, reject) => {
    let db;
    let output = undefined;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.get(100);

      response1.onsuccess = function(event) {
        resolve(event.target.result);
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error get data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        console.error("indexeddb Error get data : " + event.target.errorCode);
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("indexedDB error" + event.target.errorCode);
    };
  });
}


function indexeddbUpdateData(inputData) {
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.put({id: 100, data: inputData});

    
    response1.onerror = function(event) {
      toast.error("indexedDB error update data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("indexedDB error update data : " + event.target.errorCode);
    };
  };
  
  request.onerror = function(event) {
    toast.error("indexedDB error update data", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.error("indexedDB error: " + event.target.errorCode);
  };
}


  async function getQuestionOnce() {
    try{
      let resource_list_response = undefined;
      resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
      if(resource_list_response !== undefined){
        if (resource_list_response.data && resource_list_response.data.length > 0) {
          set_resource_available(true);
          let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
          if (conceptResponse.data.length) {
            set_concepts_data(conceptResponse.data);
            conceptArrayDuplicate.current = conceptResponse.data;
            let request_json = await resquestBodyBuild();
            const body = {};
            body["plan_type"] = plan_type;
            body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
            if (request_json.type === "question_id") {
              body["question_id"] = request_json.topic_array[0];
            } else {
              body["topic_id_list"] = request_json.topic_array;
            }
            let question_response = await getQuestionUtility(body);
            if (question_response.data.length > 0) {
              set_question_available(true);
              set_data_available(true);
              // localStorage.setItem("current_question_content",  JSON.stringify(question_response.data));
              set_current_question_content(question_response.data);
              setQuestionData(question_response.data);
              set_option_card_clickable(true);
              set_quiz_option_clicked(false);
              progressBar.current.style.opacity = "1";
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              if (request_json.type === "topic_id") {
                let topic_element = document.getElementById(
                  request_json.topic_array[0]
                );
                topic_element.checked = true;
                let temp1 = topic_element.className.split(" ");
                let temp2 = temp1[0].split("-");
                let temp3 = temp2[1];
                let resource_element = document.getElementById(`resource-${temp3}`);
                resource_element.checked = true;
              } else if (request_json.type === "resource_id") {
                request_json.topic_array.forEach((item) => {
                  let topic_element = document.getElementById(item);
                  topic_element.checked = true;
                  let temp1 = topic_element.className.split(" ");
                  let temp2 = temp1[0].split("-");
                  let temp3 = temp2[1];
                  let resource_element = document.getElementById(
                    `resource-${temp3}`
                  );
                  resource_element.checked = true;
                });
              } else if (request_json.type === "empty") {
                request_json.topic_array.forEach((item) => {
                  let topic_element = document.getElementById(item);
                  topic_element.checked = true;
                  let temp1 = topic_element.className.split(" ");
                  let temp2 = temp1[0].split("-");
                  let temp3 = temp2[1];
                  let resource_element = document.getElementById(
                    `resource-${temp3}`
                  );
                  resource_element.checked = true;
                });
              }
            } else {
              set_question_available(false);
              set_data_available(false);
              pollQuestionAvailable();
            }
          } else {
            set_question_available(false);
            set_data_available(false);
            pollQuestionAvailable();
          }
        } else {
          set_resource_available(false);
          set_data_available(false);
        }
        setAllowNext(false);
      }
    } catch(err){
      console.error("quiz",err);
    }
  }

  async function getQuestionContinuously() {
    let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
    if (conceptResponse.data.length) {
      set_concepts_data(conceptResponse.data);
      conceptArrayDuplicate.current = conceptResponse.data;
      let request_json = await resquestBodyBuild();
      const body = {};
      body["plan_type"] = plan_type;
      body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
      if (request_json.type === "question_id") {
        body["question_id"] = request_json.topic_array[0];
      } else {
        body["topic_id_list"] = request_json.topic_array;
      }
      let question_response = await getQuestionUtility(body);
      if (question_response.data.length > 0) {
        clearInterval(pollIntervalId.current);
        set_question_available(true);
        set_data_available(true);
        // localStorage.setItem("current_question_content",  JSON.stringify(question_response.data));
        set_current_question_content(question_response.data);
        setQuestionData(question_response.data);
        set_option_card_clickable(true);
        set_quiz_option_clicked(false);
        progressBar.current.style.opacity = "1";
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        if (request_json.type === "topic_id") {
          let topic_element = document.getElementById(
            request_json.topic_array[0]
          );
          topic_element.checked = true;
          let temp1 = topic_element.className.split(" ");
          let temp2 = temp1[0].split("-");
          let temp3 = temp2[1];
          let resource_element = document.getElementById(`resource-${temp3}`);
          resource_element.checked = true;
        } else if (request_json.type === "resource_id") {
          request_json.topic_array.forEach((item) => {
            let topic_element = document.getElementById(item);
            topic_element.checked = true;
            let temp1 = topic_element.className.split(" ");
            let temp2 = temp1[0].split("-");
            let temp3 = temp2[1];
            let resource_element = document.getElementById(`resource-${temp3}`);
            resource_element.checked = true;
          });
        } else if (request_json.type === "empty") {
          request_json.topic_array.forEach((item) => {
            let topic_element = document.getElementById(item);
            topic_element.checked = true;
            let temp1 = topic_element.className.split(" ");
            let temp2 = temp1[0].split("-");
            let temp3 = temp2[1];
            let resource_element = document.getElementById(`resource-${temp3}`);
            resource_element.checked = true;
          });
        }
      } else {
        set_question_available(false);
        set_data_available(false);
      }
    } else {
      set_question_available(false);
      set_data_available(false);
    }
    setAllowNext(false);
  }

  function pollQuestionAvailable() {
    let timeDuration = 0;
    pollIntervalId.current = setInterval(async () => {
      timeDuration += 20;
      if (timeDuration >= 600) {
        clearInterval(pollIntervalId.current);
        return;
      }
      try {
        getQuestionContinuously();
      } catch (e) {
        console.error(e);
      }
    }, 20000);
  }

  // @note resquestBodyBuild
  async function resquestBodyBuild() {
    let request_json = {};
    const urlParams = new URLSearchParams(window.location.search);
    let topic_array = [];
    if (urlParams.get("question_id") !== null) {
      let question_id = urlParams.get("question_id");
      topic_array.push(question_id);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "question_id";
      set_show_question_filter(false);
    } else if (urlParams.get("topic_id") !== null) {
      let topic_id = urlParams.get("topic_id");
      topic_array.push(topic_id);
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "topic_id";
    } else if (urlParams.get("resource_id") !== null) {
      let temp_resource_id = urlParams.get("resource_id");
      let resource_json = conceptArrayDuplicate.current.filter(
        (item) => item["resource_id"] === temp_resource_id
      );
      let topics_array = resource_json[0]["topic_list"];
      topics_array.forEach((item) => {
        if (item.status === "pending") {
          topic_array.push(item.id);
        }
      });
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "resource_id";
    } else {
      conceptArrayDuplicate.current.forEach((item1) => {
        item1.topic_list.forEach((item2) => {
          if (item2.status === "pending") {
            topic_array.push(item2.id);
          }
        });
      });
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "empty";
    }
    return request_json;
  }

  function storeLastThreeQuestionId(id) {
    if (lastThreeQuestionIdArray.current.length >= 3) {
      lastThreeQuestionIdArray.current.pop();
      lastThreeQuestionIdArray.current.unshift(id);
    } else {
      lastThreeQuestionIdArray.current.unshift(id);
    }
  }

  function handleUploadSuccess(response){
    getQuestionOnce();
  }

  function triggerFileUpload() {
    fileUploadRef.current.triggerFileInput();
  }

  useEffect(() => {
    answerResponseTimeStart.current = new Date();
  }, [current_question_content]);

  async function getQuestion() {
    try{
      const body = {};
      body["plan_type"] = plan_type;
      body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
      if (question_filter_array.length) {
        body["topic_id_list"] = question_filter_array;
      }
      let response = await getQuestionUtility(body);
      if (response.data && response.data.length > 0){
        localStorage.setItem(
          "current_question_content",
          JSON.stringify(response.data)
        );
        set_current_question_content(response.data);
        setQuestionData(response.data);
        localStorage.removeItem("question_seen_count");
        localStorage.removeItem("total_question_count");
        localStorage.setItem(
          "question_seen_count",
          response.data[0]["seen_question_count"]
        );
        localStorage.setItem(
          "total_question_count",
          response.data[0]["total_question_count"]
        );

        set_option_card_clickable(true);
        set_quiz_option_clicked(false);
        // progressBar.current.style.opacity = "1"
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }

      setAllowNext(false);
    } catch(err){
      console.error("quiz", err);
    }
  }


  function handleMarketingDataFirstQuestionAnswered(){
    try{
      // google analytics
      // CreateGtagEvent("first_questions", {
      //   user_id: String(localStorage.getItem("userId")) || "",
      // });
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_Question_Replied",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.error('quiz page', error)
    }
  }


  function handleMarketingDataConceptCompleted(){
    try{
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Concept_completed",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.error('quiz page', error)
    }    
  }


  function save_answer_to_db(answer_is_correct, clicked_option) {
    storeLastThreeQuestionId(current_question_content[0]["question_id"]);

    let temp_response_ms =
      answerResponseTimeEnd.current.getTime() -
      answerResponseTimeStart.current.getTime();

    let qAns;

    var getAns = current_question_content[0].question_content.options;
    for (let i = 0; i < getAns.length; i++) {
      const element = getAns[i];
      if (element.is_correct) {
        qAns = element.text;
      }
    }

    let body = {
      question_id: current_question_content[0]["question_id"],
      topic_id: current_question_content[0]["topic_id"],
      is_correct: answer_is_correct,
      response_ms: temp_response_ms,
      question_name: current_question_content[0].question_content["question"],
      complexity_score: 2.5,
      question_answer: qAns,
      resource_id: current_question_content[0]["resource_id"],
      is_answered: current_question_content[0]["is_answered"],
      question_current_answer: clicked_option.text,
    };

    let isanswered_first_question = JSON.parse(localStorage.getItem("eQuestionFlag"));
    if (isanswered_first_question === false) {
      body["isanswered_first_question"] = true;
      localStorage.setItem("eQuestionFlag", true);
      handleMarketingDataFirstQuestionAnswered();
      // let temp_user_id = parseInt(JSON.parse(localStorage.getItem("userId")));
      // // CreateGtagEvent("first_questions", {
      // //   user_id: temp_user_id,
      // // });
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/answer`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const urlParams = new URLSearchParams(window.location.search);
          var keyOfObj;
          var valueOfObj;
          // urlParams.forEach((value, key) => {
          //   keyOfObj = key;
          //   valueOfObj = value;
          // });
          if (urlParams.get("question_id") !== null) {
            keyOfObj = "question_id";
            valueOfObj = urlParams.get("question_id");
          } else if (urlParams.get("topic_id") !== null) {
            keyOfObj = "topic_id";
            valueOfObj = urlParams.get("topic_id");
          } else if (urlParams.get("resource_id") !== null) {
            keyOfObj = "resource_id";
            valueOfObj = urlParams.get("resource_id");
          } else {
            keyOfObj = "filter_status";
            valueOfObj = "off";
          }

          if (response.data.topic_score_display_60_percent) {
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=60`
            );
          }
          if (response.data.topic_score_display_100_percent) {
            handleMarketingDataConceptCompleted()
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=100`
            );
          }
          if (response.data.first_three_questions_display) {
            let data = {
              show: response.data.first_three_questions_display,
              conceptId: response.data.topic_id,
            };
            set_first_three_questions_display(data);
          }
        }
      });
  }

  function optionClickHandler(selected_option, option_json) {
    progressBar.current.style.opacity = "1";
    set_show_answer_in_feedback(false);

    setAllowNext(true);
    set_option_card_clickable(false);
    global_answer_type.current = option_json["is_correct"];
    answerResponseTimeEnd.current = new Date();

    let options_node_list = document.getElementsByClassName(
      "mcq-new-option-card"
    );
    // reset options color
    Array.from(options_node_list).forEach((element) => {
      element.style.backgroundColor = "#fff";
    });

    let temp_quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );
    temp_quiz_guide_text_show_count =
      parseInt(temp_quiz_guide_text_show_count) + 1;
    localStorage.setItem(
      "quiz_hints_guide_text_show_count",
      temp_quiz_guide_text_show_count
    );
    // guide text visible
    let quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );

    if (quiz_guide_text_show_count > 2) {
      quiz_guide_text.current.style.display = "none";
      quiz_guide_icon.current.style.display = "none";
    }

    // display quiz tips
    progressBarContainer.current.style.visibility = "visible";
    progressBarContainer.current.style.transform = "translateY(0%)";
    // transform

    // progressBar.current.style.animation= `slide 4s forwards`;

    // conditional baed style for options
    if (option_json["is_correct"] === true) {
      setAllowNext(true);
      //option color
      let quizOptionCard = document.getElementById(selected_option);
      quizOptionCard.style.backgroundColor = "var(--light-green)";
      // selected_option.style.backgroundColor = "var(--light-green)";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Correct!";
      //progress bar color
      progressBar.current.style.backgroundColor = "var(--light-green)";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "none";

      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "center";
      }

      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "none";
      }
    } else if (option_json["is_correct"] === false) {
      setAllowNext(true);
      //option color
      let quizOptionCard = document.getElementById(selected_option);
      quizOptionCard.style.backgroundColor = "var(--light-orange)";
      // selected_option.style.backgroundColor = "var(--light-orange)";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Not quite";
      //progress bar color
      progressBar.current.style.backgroundColor = "var(--light-orange)";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "flex";
      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "block";
      }
      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "center";
      }
    }

    // quiz tips explanation
    quiz_hints_content.current.innerHTML = option_json["explanation"];
    save_answer_to_db(option_json["is_correct"], option_json);

    clearSetInterval.current = setTimeout(() => {
      if (transition_next_quiz.current === true) {
        Array.from(options_node_list).forEach((element) => {
          // element.style.backgroundColor = "#fff";
        });

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.transform= `translateY(70%)`;
        }

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.visibility = "hidden";
        }
        if (progressBar.current) {
          progressBar.current.style.animation = `none`;
        }

        question_count.current += 1;
        // if question_id present redirect to home
        // const urlParams = new URLSearchParams(window.location.search);
        // if (urlParams.get('question_id') !== null){
        //   if(urlParams.get('library') !== null){
        //     navigate("/library");
        //   } else if(urlParams.get('concept_topic_id') !== null){
        //     let topic_id = urlParams.get('concept_topic_id');
        //     navigate(`/conceptquestion?topic_id=${topic_id}`);
        //   } else{
        //     navigate("/home");
        //   }
        // }
        // var keyOfObj;
        // var valueOfObj;
        // urlParams.forEach((value, key) => {
        //   keyOfObj = key;
        //   valueOfObj = value;
        // });
        // if (keyOfObj === "question_id") {
        //   navigate("/home");
        //   return;
        // }
        // getQuestion();
      }
    }, delay_new);
  }

  // @note move question
  function move_to_next_quiz(message, question_type) {
    if (question_type === "question") {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("question_id") !== null) {
        if (urlParams.get("library") !== null) {
          navigate("/library");
          return;
        } else if (urlParams.get("concept_topic_id") !== null) {
          let topic_id = urlParams.get("concept_topic_id");
          navigate(`/conceptquestion?topic_id=${topic_id}`);
          return;
        } else {
          navigate("/home");
          return;
        }
      }
      if (message === true) {
        global_answer_type.current = null;
        transition_next_quiz.current = true;
        let options_node_list = document.getElementsByClassName(
          "mcq-new-option-card"
        );
        Array.from(options_node_list).forEach((element) => {
          element.style.backgroundColor = "#fff";
        });
        progressBarContainer.current.style.visibility = "hidden";
        progressBar.current.style.animation = `none`;
        question_count.current += 1;
        getQuestion();
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("question_id") !== null) {
        if (urlParams.get("library") !== null) {
          navigate("/library");
          return;
        } else if (urlParams.get("concept_topic_id") !== null) {
          let topic_id = urlParams.get("concept_topic_id");
          navigate(`/conceptquestion?topic_id=${topic_id}`);
          return;
        } else {
          navigate("/home");
          return;
        }
      }
      if (message === true && quiz_option_clicked === true) {
        global_answer_type.current = null;
        transition_next_quiz.current = true;
        let options_node_list = document.getElementsByClassName(
          "mcq-new-option-card"
        );
        Array.from(options_node_list).forEach((element) => {
          element.style.backgroundColor = "#fff";
        });
        progressBarContainer.current.style.visibility = "hidden";
        progressBar.current.style.animation = `none`;
        question_count.current += 1;
        getQuestion();
      }
    }
  }

  function continuousThreeAnswerClose(value) {
    set_first_three_questions_display(value);
  }

  function pauseQuiz(target) {
    let quiz_guide_text_hover_count = localStorage.getItem(
      "quiz_hints_guide_text_hover_count"
    );
    quiz_guide_text_hover_count = parseInt(quiz_guide_text_hover_count) + 1;
    localStorage.setItem(
      "quiz_hints_guide_text_hover_count",
      quiz_guide_text_hover_count
    );

    if (!target.className.includes("quiz-hints-icon-func")) {
      progressBar.current.style.animationPlayState = "paused";
      progressBarContainer.current.style.visibility = "visible";
      transition_next_quiz.current = false;
    }
  }
  function resumeQuiz(message, target) {
    if (!target.className.includes("quiz-hints-icon-func")) {
      transition_next_quiz.current = true;
    }
    if (!target.className.includes("quiz-hints-icon-func")) {
      progressBar.current.style.animationPlayState = "running";
      setTimeout(() => {
        if (message === true && transition_next_quiz.current === true) {
          global_answer_type.current = null;
          transition_next_quiz.current = true;
          let options_node_list = document.getElementsByClassName(
            "mcq-new-option-card"
          );
          Array.from(options_node_list).forEach((element) => {
            element.style.backgroundColor = "#fff";
          });
          progressBarContainer.current.style.visibility = "hidden";
          progressBar.current.style.animation = `none`;
          question_count.current += 1;
          // getQuestion();
        }
      }, 2000);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Tab" || event.key === "ArrowRight") {
        if (allownext && !pdfopen) {
          if (progressBarContainer.current) {
            progressBarContainer.current.style.visibility = "hidden";
          }
          if (progressBarContainer.current) {
            progressBarContainer.current.style.transition = "all 1s ease";
            progressBarContainer.current.style.transform = `translateY(150%)`;
          }
          let options_node_list = document.getElementsByClassName(
            "mcq-new-option-card"
          );
          // reset options color
          Array.from(options_node_list).forEach((element) => {
            element.style.backgroundColor = "#fff";
          });
          question_id_redirect();

          // getQuestion();
          // move_to_next_quiz();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [allownext, pdfopen]);

  const validateNextQues = () => {
    if (allownext) {
      if (progressBarContainer.current) {
        progressBarContainer.current.style.visibility = "hidden";
      }
      if (progressBarContainer.current) {
        progressBarContainer.current.style.transition = "all 1s ease";
        progressBarContainer.current.style.transform = `translateY(150%)`;
      }
      let options_node_list = document.getElementsByClassName(
        "mcq-new-option-card"
      );
      // reset options color
      Array.from(options_node_list).forEach((element) => {
        element.style.backgroundColor = "#fff";
      });
      question_id_redirect();

      // move_to_next_quiz();
    }
  };

  function question_id_redirect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("question_id") !== null) {
      if (urlParams.get("library") !== null) {
        navigate("/library");
        return;
      } else if (urlParams.get("concept_topic_id") !== null) {
        let topic_id = urlParams.get("concept_topic_id");
        navigate(`/conceptquestion?topic_id=${topic_id}`);
        return;
      } else if (urlParams.get("redirect") === "reread") {
        navigate(
          `/reread?focus=${urlParams.get("question_id")}&page=${urlParams.get(
            "page"
          )}`
        );
        return;
      } else {
        navigate("/home");
        return;
      }
    } else {
      getQuestion();
    }
  }

  const handleTouchStart = (e) => {
    initialX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (initialX === null) return;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - initialX;

    if (deltaX < 0) {
      if (allownext) {
        validateNextQues();
      }
    }

    initialX = null;
  };

  function handleConceptCheckbox(id, target, classname = "", eleId = "") {
    if (target.checked) {
      set_question_filter_array((prevItems) => [...prevItems, id]);
      let y = document.getElementById(eleId);
      y.checked = true;
    } else {
      const updatedArray = question_filter_array.filter((item) => item !== id);
      set_question_filter_array(updatedArray);
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptCheckedCount = 0;
      Array.from(concept_ele_list).forEach((element) => {
        if (element.checked) {
          conceptCheckedCount += 1;
        }
      });
      if (conceptCheckedCount <= 0) {
        let y = document.getElementById(eleId);
        y.checked = false;
      }
    }
  }

  function handleResourceCheckbox(target, classname = "", conceptArray = []) {
    if (target.checked) {
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptIdArray = [];
      conceptArray.forEach((item) => {
        if (item.status === "pending") {
          conceptIdArray.push(item.id);
        }
      });
      set_question_filter_array((prevItems) => [
        ...prevItems,
        ...conceptIdArray,
      ]);
      Array.from(concept_ele_list).forEach((element) => {
        element.checked = true;
      });
    } else {
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptIdArray = [];
      conceptArray.forEach((item) => {
        if (item.status === "pending") {
          conceptIdArray.push(item.id);
        }
      });
      const updatedArray = question_filter_array.filter(
        (item) => !conceptIdArray.includes(item)
      );
      set_question_filter_array(updatedArray);

      Array.from(concept_ele_list).forEach((element) => {
        element.checked = false;
      });
    }
  }

  //Get PDF  file from server and display it in the PDF Viewer
  async function handleClickReread(data) {
    setPdfOpen(true);
    setLoaderStatus(true);
    // read blob data present in the indexedDB
    // if present in indexedDB load from local data
    let doesLocalBlobPresent = false;
    let localBlobData;
    let tempFileName1 = data.resource_url.split("/");
    let tempFileName2 = tempFileName1[tempFileName1.length - 2];
    let blobArray = await indexeddbGetData();
    if(blobArray){
      let tempArray = blobArray.data;
      let foundItem = tempArray.filter(item => item["resourceId"] === tempFileName2);
      if(foundItem.length > 0){
        doesLocalBlobPresent = true;
        localBlobData = foundItem[0]["fileBlob"];
      }
    }
    if(doesLocalBlobPresent){
      // Open pdf reader popup
      let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
      if (pdfContainerQuiz.length) {
        // containerRef.current.style.opacity = "0.2";
        // progressBarContainer.current.style.opacity = "0.2";
        pdfContainerQuiz[0].style.transform = "translateX(0)";
        pdfContainerQuiz[0].style.visibility = "visible";
      }
      // set pdf url from blob
      setLoaderStatus(false);
      setpdf(URL.createObjectURL(localBlobData));
      setPageNumber(data.matching_page - 1);
      setTimeout(() => {
        setPdfLoaded(true);
      }, 2500);
    } else {
      const body = { resource_uri: data.resource_url };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/reread-pdf`, requestOptions)
        .then((response) => response.blob())
        .then(async (response) => {
          let tempRes = response;
          // Open pdf reader popup
          let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
          if (pdfContainerQuiz.length) {
            // containerRef.current.style.opacity = "0.2";
            // progressBarContainer.current.style.opacity = "0.2";
            pdfContainerQuiz[0].style.transform = "translateX(0)";
            pdfContainerQuiz[0].style.visibility = "visible";
          }
          // set pdf url from blob
          setLoaderStatus(false);
          setpdf(URL.createObjectURL(response));
          setPageNumber(data.matching_page - 1);
          setTimeout(() => {
            setPdfLoaded(true);
          }, 2500);
          // store blob data to indexedDB
          let tempFileName1 = data.resource_url.split("/");
          let tempFileName2 = tempFileName1[tempFileName1.length - 2]
          let blobArray = await indexeddbGetData();
          if(blobArray){
            let tempArray = blobArray.data;
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbUpdateData(tempArray);
          } else {
            let tempArray = [];
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbAddData(tempArray);
          }
        });
    }

  }

  function handleClosePdf() {
    setPdfOpen(false);
    // containerRef.current.style.opacity = "1";
    // progressBarContainer.current.style.opacity = "1";
    let pdfContainerQuiz =
      document.getElementsByClassName("pdf-container-quiz");
    if (pdfContainerQuiz.length) {
      pdfContainerQuiz[0].style.transform = "translateX(100%)";
      setTimeout(() => {
        pdfContainerQuiz[0].style.visibility = "hidden";
        setPdfLoaded(false);
      }, 600);
    }
  }

  // ====================== outside click close pdf ====================

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        pdfloaded &&
        pdf_section_container.current &&
        !pdf_section_container.current.contains(event.target)
      ) {
        handleClosePdf();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [pdfloaded]);

  // ================ PDF swipe left to right close =======================
  // const handleTouchStartPDF = (e) => {
  //   setStartPosX(e.touches[0].clientX);
  // };

  // const handleTouchMovePDF = (e) => {
  //   setEndPosX(e.touches[0].clientX);
  // };

  // const handleSwipeEnd = () => {
  //   if (startPosX && endPosX && startPosX < endPosX) {
  //     // Swiped from left to right
  //     if (window.innerWidth < 600) {
  //       // handleClosePdf();
  //     }
  //     // Trigger your event or call a function here
  //   }

  //   setStartPosX(null);
  //   setEndPosX(null);
  // };

  // ================ outside feedback touch go to next question =======================

  const [timerId, setTimerId] = useState(null);

  // Function to be called after 2 seconds
  const delayedFunction = (event) => {
    if (
      progressBarContainer.current &&
      !progressBarContainer.current.contains(event.target)
    ) {
      if (window.innerWidth < 600 && allownext) {
        // validateNextQues();
      }
    }
    // Your logic here
  };

  // Event handler for touch start
  const handleTouchStartPDF = (event) => {
    // Start the timer
    const id = setTimeout(delayedFunction(event), 1000);
    setTimerId(id);
  };

  // Event handler for touch move
  const handleTouchMovePDF = () => {
    // If touch move is triggered, clear the timer
    clearTimeout(timerId);
  };


  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  }

  function uiCurrentQuizTopicCard(){
    return(
      <>
        <div className={style["topic-card"]}>
          <div className={style["topic-card-header"]}>
            <div className={style["topic-card-header-left"]}>
              <h3>Laplace Transform Response Function...
              </h3>
              <span>pages 11 - 15</span>
            </div>
            <div className={style["topic-card-header-right"]}>              
              <div className={style["topic-card-progress-bar"]}>
                <div 
                    style={{width:`${30}%`, backgroundColor:"var(--light-green)"}}
                    className={`${style["progress-bar-child"]}`}>
                </div>
                <div 
                    style={{width: `${20}%`, backgroundColor:"var(--light-orange)"}}
                    className={`${style["progress-bar-child"]}`}>
                </div>
                <div 
                    style={{width: `${40}%`, backgroundColor:"var(--refresh-violet)"}}
                    className={`${style["progress-bar-child"]}`}>
                </div>
                <div 
                    style={{width: `${40}%`, backgroundColor:"var(--light-blue)"}}
                    className={`${style["progress-bar-child"]}`}>
                </div>
              </div>
              <div onClick={(e)=>{set_show_topic_card_questions(!show_topic_card_questions)}} className={style["topic-card-open-icon"]}>
                <img
                  style={show_topic_card_questions === true ?
                    {transform: "rotate(0deg)"}
                    :
                    {transform: "rotate(180deg)"}
                  }

                src={downArrowIcon} alt="open-icon"/>
              </div>
            </div>
          </div>
          <div
            style={show_topic_card_questions ? {display: "block"} : {display: "none"}}
            className={style["topic-card-question-list"]}>    
            <div className={style["topic-question-header"]}>
            <div className={style["topic-question-header-left"]}>
              <h2>Question</h2>
            </div>
            <div className={style["topic-question-header-right"]}>
              <h2>Status</h2>
            </div>
            </div>
            <div className={style["topic-question"]}>
              <div className={style["topic-question-left"]}>
                <h3>What is the primary purpose of nodes in an electrical circuit?</h3>
              </div>
              <div className={style["topic-question-right"]}>
                <aside 
                  style={true 
                    ? 
                      { backgroundColor: "var(--light-green)"} 
                    : 
                      false
                        ?
                          { backgroundColor: "var(--refresh-violet)"}
                        :
                          false
                          ?
                            { backgroundColor: "var(--light-orange)"}
                          :
                            false
                            ?
                              { backgroundColor: "var(--light-blue)"}
                            :
                              ""
                  }>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  function uiChapterTopicToggleBtn(){
    return(
      <>
        <div className={style["chapters-topics-toggle-btn"]}>
          <article
            onClick={()=>{set_chapters_topics_btn("chapters")}}
            className={chapters_topics_btn === "chapters" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
          >
            Chapters
          </article>
          <article
            onClick={()=>{set_chapters_topics_btn("topics")}}
            className={chapters_topics_btn === "topics" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
          >
            Topics
          </article>
        </div>
      </>
    )
  }

  function uiShuffleModeToggle(){
    return(
      <>
        <div className={`${style["shuffle-btn-container"]}`}>
          <span className={style["shuffle-btn-text"]}>shuffle mode</span>
          <div className={style["shuffle-btn-box"]}>
            <label className={style["shuffle-switch"]}>
              <input onChange={handleChange} type="checkbox"/>
              <span className={style["shuffle-slider"]}></span>
            </label>
          </div>
        </div>
      </>
    )
  }
  const handleChange = (event) => {
    setEnableSuffleMode(event.target.checked);
    enableSuffleModeRef.current = event.target.checked;
    console.log('event.target.checked', event.target.checked)
    console.log('enableSuffleMode', enableSuffleMode)
  };


  return (
    <>
      {/* <section
      style={plan_type === "free" ? {display: "flex"} : {display: "none"}}
      className={style["question-counter-container"]}>
      <div className={style["question-counter-content"]}>
        <p>
          <span id="quiz_freemium_topbar_go_to_push_mode" onClick={()=>{navigate("/pushmode")}}>Unlock all questions now. </span>
          {
            current_question_content.length
              ?
                <>
                  Free questions seen ( {current_question_content[0].seen_question_count ? current_question_content[0].seen_question_count : 0 } / {current_question_content[0].total_question_count ? current_question_content[0].total_question_count : 0 })
                </>
              :
                <>
                  Free questions seen (0/0)
                </>
          }
          <img 
            onMouseOver={()=>{
              question_counter_helptext.current.style.display = "block";
            }}
            onMouseOut={()=>{
              question_counter_helptext.current.style.display = "none";
            }}
            style={window_width <= 500 ? {display: "none"} : {display: "block"}}
            src={questionIcon} alt="question icon" />
        </p>
        <div ref={question_counter_helptext} className={style["question-counter-info-text"]}>
            In free mode you see questions only from the first pages of your document.
            <strong onClick={()=>{navigate("/pushmode")}}>Go push mode to go unlimited</strong>
        </div>
      </div>
    </section> */}
      {/* <Sidebar/> */}
      <section
        className="quiz-empty-state-container"
        style={
          data_available === false ? { display: "flex" } : { display: "none" }
        }
      >
        <div className="quiz-empty-state-content">
          {/* upload file UI */}
          <div
            className="quiz-empty-state-upload-file"
            style={
              resource_available === false
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <p>Upload at least 1 document to see some questions here</p>
            <FileUploadComponent
              ref={fileUploadRef}
              onUploadSuccess={handleUploadSuccess}
              examspaceId={global_default_examspace_id.current}
            />
            <button onClick={triggerFileUpload}>
              <img src={uploadIcon} alt="upload icon"/>
              Upload a file
            </button>
          </div>
          {/* Generating questions UI */}
          <div
            className="quiz-empty-state-generate"
            style={
              question_available === false
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <img src={loadingsmall} alt="loading" />
            <p>
              We are generating questions from your documents. Please hold on a
              few seconds
            </p>
          </div>
        </div>
      </section>

      <div
        style={
          data_available === true ? { display: "block" } : { display: "none" }
        }
      >
        {uiCurrentQuizTopicCard()}
        <section className={`${style["filter-container"]}`}>
          <div className={`${style["filter-content"]}`}>
            { window_width >= 1000 ? uiShuffleModeToggle() : "" }
            <div
              style={
                show_question_filter === true
                  ? { display: "block" }
                  : { display: "none" }
              }
              className={`${style["question-filter-container"]}`}
            >
              {console.log('enableSuffleMode jsx', enableSuffleMode)}
              <p
                onClick={() => {
                  set_show_quiz_filter(!enableSuffleMode)
                }}
                className={`${style["question-filter-menu-desktop"]}`}
              >
                <FilterIcon/>
                <p>Filters</p>
              </p>
              <p
                onClick={() => {set_show_quiz_filter(true)}}
                className={`${style["question-filter-menu-mobile"]}`}
              >
                {
                  show_quiz_filter === false
                  ?
                    <FilterIcon/>
                  :
                    <img onClick={()=>{set_show_quiz_filter(false)}} src={closeIcon} alt="icon"/>
                }
              </p>
              <div
                // ref={question_filter_content}
                style={show_quiz_filter === true ? {display: "block"} : {display: "none"} }
                className={`${style["question-filter-content"]}`}
              >
                {/* filter header section */}
                <div className={`${style["question-filter-header"]}`}>
                  <div className={`${style["question-filter-action-btns"]}`}>
                    {uiChapterTopicToggleBtn()}
                    {window_width < 1000 ? uiShuffleModeToggle() : null}
                  </div>
                  <div className={`${style["question-filter-title"]}`}>
                    { true ? "Chapters" : ""}
                  </div>
                </div>

                {concepts_data.length > 0 ? (
                  concepts_data.map((item1, index1) => {
                    return (
                      <>
                        <div style={{paddingBottom: "5px",}}>
                          <article
                            className={`${style["resource-item"]}`}
                          >
                            <label>
                              <input
                                id={`resource-${index1}`}
                                onChange={(e) => {
                                  handleResourceCheckbox(
                                    e.target,
                                    `res-${index1}-concept`,
                                    item1.topic_list
                                  );
                                }}
                                type="checkbox"
                              />
                              <span>
                                {item1["resource_title"].length > 30
                                  ? item1["resource_title"].substring(0, 30) +
                                    "..."
                                  : item1["resource_title"]}
                              </span>
                            </label>
                            <img
                              style={{ display: "block" }}
                              src={downArrowBlack}
                              alt="arrow"
                              onClick={(event) => {
                                let x = document.getElementById(
                                  `concept-block-${index1}`
                                );
                                // x.style.display="none";
                                if (
                                  x.style.display === "none" ||
                                  x.style.display === ""
                                ) {
                                  x.style.display = "block";
                                  event.target.style.transform = "rotate(0deg)";
                                } else {
                                  x.style.display = "none";
                                  event.target.style.transform =
                                    "rotate(-90deg)";
                                }
                              }}
                            />
                          </article>
                          {/* @note question filter html */}
                          <section
                            style={{ marginLeft: "10px", marginTop: "10px" }}
                            id={`concept-block-${index1}`}
                          >
                            {item1.topic_list.length > 0 ? (
                              item1.topic_list.map((item) => {
                                return (
                                  <>
                                    <aside
                                      // style={item.status === "pending" ? {pointerEvents: "auto"} : {pointerEvents: "none"}}
                                      className={`${style["concept-item"]}`}
                                    >
                                      <img
                                        id="quiz_filter_go_to_push_mode"
                                        onClick={() => {
                                          navigate("/pushmode");
                                        }}
                                        style={
                                          item.status === "pending"
                                            ? { display: "none" }
                                            : {
                                                display: "block",
                                                cursor: "pointer",
                                              }
                                        }
                                        src={lockIcon}
                                        alt="lock icon"
                                      />
                                      <label
                                        style={
                                          item.status === "pending"
                                            ? { pointerEvents: "auto" }
                                            : { pointerEvents: "none" }
                                        }
                                      >
                                        <input
                                          id={`${item["id"]}`}
                                          className={
                                            item.status === "pending"
                                              ? `res-${index1}-concept`
                                              : ""
                                          }
                                          onChange={(e) => {
                                            handleConceptCheckbox(
                                              item.id,
                                              e.target,
                                              `res-${index1}-concept`,
                                              `resource-${index1}`
                                            );
                                          }}
                                          type="checkbox"
                                        />
                                        <p>
                                          {item["name"].length > 20
                                            ? item["name"].substring(0, 20) +
                                              "..."
                                            : item["name"]}
                                        </p>
                                      </label>
                                      <span
                                        style={
                                          item["pages"] === ""
                                            ? { display: "none" }
                                            : { display: "inline" }
                                        }
                                      >
                                        {`pages `}
                                        {item["pages"]}
                                      </span>
                                    </aside>
                                    {/* <p style={{borderBottom: "1px solid #000"}}></p> */}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </section>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>

        <main
          ref={containerRef}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchMove={(e) => handleTouchMove(e)}
          className="mcq-new-container"
          style={{ opacity: allownext ? "0.2" : "" }}
        >
          <div className="mcq-new-wrapper">
            {/* <div className="box" ></div> */}
            {current_question_content.length ? (
              <section className="mcq-new-question-area">
                <div className="mcq-new-question-heading">
                  <div className={`${style["quiz-question-info"]}`}>
                    {true ? <div className={`${style["quiz-question-info-indicator"]}`}></div> : <></>}
                    {false ? <div className={`${style["quiz-question-info-indicator"]}`}></div> : <></>}
                    {false ? <div className={`${style["quiz-question-info-indicator"]}`}></div> : <></>}
                    {false ? <div className={`${style["quiz-question-info-indicator"]}`}></div> : <></>}
                    <div className={`${style["quiz-question-info-text"]}`}>
                      2d
                    </div>
                  </div>
                  <div className={`${style["quiz-question-text"]}`}>
                    {current_question_content[0].question_content["question"]}
                  </div>
                </div>
                <article className="mcq-new-options-container">
                  {current_question_content[0].question_content["options"] ? (
                    <>
                      {current_question_content[0].question_content[
                        "options"
                      ].map((item, quizOptionsIndex) => {
                        return (
                          <div
                            className="mcq-new-option-card"
                            id={`mcq-option-card-${quizOptionsIndex}`}
                            key={quizOptionsIndex}
                            style={
                              option_card_clickable
                                ? { pointerEvents: "auto" }
                                : { pointerEvents: "none" }
                            }
                            onClick={(e) => {
                              optionClickHandler(`mcq-option-card-${quizOptionsIndex}`, item);
                              set_quiz_option_clicked(true);
                              // set_show_answer_in_feedback(false);
                            }}
                          >
                            <div className="quiz-options-sequence">
                              <span>
                                {quizOptionsIndex === 0 ? "a" : ""}
                                {quizOptionsIndex === 1 ? "b" : ""}
                                {quizOptionsIndex === 2 ? "c" : ""}
                                {quizOptionsIndex === 3 ? "d" : ""}
                              </span>
                            </div>
                            <div className="quiz-options-text">
                              {item["text"]}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </article>
              </section>
            ) : null}
          </div>
        </main>
        {/* when a quiz in taken, then if user click any where in the screen move to next quiz exluding 
        (quiz feedback box at bottom, next quiz arrow at right and filters on top) */}
        <section 
          className={`${style["next-quiz-clickable-backdrop"]}`}
          onClick={() => validateNextQues()}
          style={{ display: allownext ? "" : "none" }}>
        </section>
        <section
          ref={progressBarContainer}
          className="mcq-progress-bar-container"
        >
          <p
            id="quiz_answer_feedback_popup_open"
            onClick={() => {
              set_feedback_popup_options({
                open: true,
                type: `answer_${global_answer_type.current}`,
                question_id: current_question_content[0].question_id,
                question_title:
                  current_question_content[0]["question_content"]["question"],
              });
              transition_next_quiz.current = false;
            }}
            className="mcq-new-tips-feedback-icon quiz-hints-icon-func"
          >
            <img
              className="quiz-hints-icon-func"
              src={blueThumbsDown}
              alt="thumbs down icon"
            />
          </p>
          <div ref={progressBar} className="mcq-progress-bar"></div>
          <div className="mcq-progress-bar-content">
            <div
              style={
                show_answer_in_feedback === false
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <h3 ref={quiz_hints_title}>Quiz feedback</h3>
              <p ref={quiz_hints_content}>
                This option accurately states the number of missions ISRO has
                launched to the Moon and Mars as mentioned in the passage.
              </p>
              <div className="feedback-menu-container">
                <h4
                  ref={feedback_see_ans_btn}
                  className="quiz-feedback-btn feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "0";
                  }}
                >
                  <img src={correctAnsIcon} alt="correct icon" />
                  <span>Correct answer</span>
                </h4>
                <h4
                  className="quiz-feedback-btn feedback-see-btn-box"
                  onClick={() => handleClickReread(current_question_content[0])}
                  id="quiz_page_read_again_button2"
                >
                  <img src={searchBlackIcon} alt="search icon" />
                  <span>Read again</span>
                </h4>
              </div>
            </div>
            <div
              style={
                show_answer_in_feedback === true
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <p className="feedback-see-ans-content">
                {current_question_content.length
                  ? current_question_content[0].question_content.options.map(
                      (item) => {
                        if (item.is_correct === true) {
                          return (
                            <>
                              <h5>{item.text}</h5>
                              <h6>{item.explanation}</h6>
                            </>
                          );
                        }
                      }
                    )
                  : ""}
              </p>
              <div className="feedback-menu-container">
                <h4
                  className="quiz-feedback-btn feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "1";
                  }}
                >
                  <span>Back to feedback</span>
                </h4>

                <h4
                  onClick={() => handleClickReread(current_question_content[0])}
                  className="quiz-feedback-btn feedback-see-btn-box"
                  id="quiz_page_read_again_button1"
                >
                  <img src={searchBlackIcon} alt="search icon" />
                  <span>Read again</span>
                </h4>
              </div>
            </div>
          </div>
        </section>
        <div ref={quiz_guide_icon}>
          <div
            id="quiz_next_question_mobile"
            onClick={() => validateNextQues()}
            className="nextQuizClickMob"
            style={{ display: allownext ? "" : "none" }}
          >
            <div>
              <div className="arrorIcon">
                <img src={swipeImg} alt="thumbs down icon" />
                {/* <AiOutlineUpCircle /> */}
              </div>
            </div>
          </div>
        </div>

        <div ref={quiz_guide_text}>
          <div className="tabMenu" style={{ display: allownext ? "" : "none" }}>
            <div>
              <div className="text">
                <p>Use “Tab” to move forward</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeedbackPopup
        props={feedback_popup_options}
        nextQuiz={move_to_next_quiz}
      ></FeedbackPopup>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
      <div
        id="quiz_next_question"
        onClick={() => validateNextQues()}
        className="nextQuizClick"
        style={{ display: allownext ? "" : "none" }}
      >
        <div>
          <div className="arrorIcon">
            <AiOutlineUpCircle />
          </div>
        </div>
      </div>
      <ContinuousThreeAnswer
        props={first_three_questions_display}
        closePage={continuousThreeAnswerClose}
      />

      {/* PDF container */}

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        {" "}
      </Worker>
      <section 
        className={`${style["pdf-viewer-backdrop"]}`}
        style={{ display: pdfopen ? "" : "none" }}
        onClick={(e)=>{handleClosePdf()}}
        ></section>
      <div ref={pdf_section_container} className="pdf-container-quiz">
        {getpdf ? (
          <div
            className="rpv-core__viewer"
            style={{
              // border: "1px solid rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: "5px",
              overflow: "auto",
              backgroundColor: "white",
              minHeight: "100vh",
            }}
          >
            <div className={"pdf_tools_container"}>
              {/* For back */}
              <div
                onClick={() => handleClosePdf()}
                className="pdf_tools_container_close"
              >
                {window.innerWidth > 600 ? (
                  <>
                    <XMarkIcon style={{ width: "22px", stroke: "black" }} />
                    <p>Close</p>
                  </>
                ) : (
                  <>
                    <ChevronLeftIcon className={"icon-left"} />
                    <p className={style["back-to-question"]}>
                      back to questions{" "}
                    </p>
                  </>
                )}
              </div>
              <div className="pdf_tools_container-menu">
                {/* For navigations */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <div>
                    <GoToPreviousPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronUpIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div>
                    <GoToNextPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            background: "white",
                            // color: RenderGoToPageProps.isDisabled
                            //   ? "#96ccff"
                            //   : "#357edd",
                            border: "none",
                            // color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronDownIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToNextPage>
                  </div>

                  <div style={{ padding: "0px 2px" }}>
                    <CurrentPageInput />
                  </div>

                  {/* ${RenderCurrentPageLabelProps.currentPage + 1} */}
                  <CurrentPageLabel>
                    {(RenderCurrentPageLabelProps) => (
                      <span>{`
                     of ${RenderCurrentPageLabelProps.numberOfPages}`}</span>
                    )}
                  </CurrentPageLabel>
                </div>

                {/* For zoom */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <ZoomOut>
                    {(RenderZoomOutProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomOutProps.onClick}
                      >
                        <MinusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomOut>
                  <ZoomIn>
                    {(RenderZoomInProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomInProps.onClick}
                      >
                        <PlusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomIn>
                </div>
              </div>
              <div></div>
            </div>

            <div
              // onTouchStart={handleTouchStartPDF}
              // onTouchMove={handleTouchMovePDF}
              style={{
                flex: 2,
                overflow: "hidden",
              }}
            >
              <Viewer
                initialPage={pagenumber}
                fileUrl={getpdf}
                plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
